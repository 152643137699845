// ========================================= //
// wysiwyg
// ========================================= //

h2, .h2 {}

h3, .h3 {}

h4, .h4 {}

.c-wysiwyg {
    img {
        &.alignright,
        &.align-right {
            float: right;
        }

        &.alignleft,
        &.align-left {
            float: left;
        }

        &.aligncenter,
        &.align-center {
            display: block;
            clear: both;
            margin-left: auto;
            margin-right: auto;
        }
    }

    p {}

    a {}

    blockquote {}

    ul, ol {}

    ul {
        li {}
    }

    ol {
        counter-reset: section;

        li:before {
            counter-increment: section;
            content: counter(section);
        }
    }

    table {
        td, th {}

        thead {
            tr {
                th {}
            }
        }

        th {}

        tbody {
            td {}
        }

        tr:nth-of-type(even) {}
    }
}

.c-table__wrapper {
    width: 100%;
    overflow-y: auto;
    _overflow: auto;
    margin: 0 0 $gutter;

    &::-webkit-scrollbar
    {
        -webkit-appearance: none;
        width: rem(14);
        height: rem(14);
    }

    &::-webkit-scrollbar-thumb
    {
        border-radius: rem(8);
        border: rem(3) solid $white;
        background-color: rgba(0, 0, 0, .3);
    }

    table {
        margin-bottom: 0;
    }
}
