.c-videos__wrapper {
    background-color: $grey--02;
    padding-top: rem(50);
    padding-bottom: rem(50);

    @include bp-lt($small) {
        background-color: $white;
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
    }

    .medium-9 {
        @include bp-lt($tablet) {
            width: 85%;
        }

        @include bp-lt($small) {
            width: 100%;
        }
    }
}

.c-video {
    float: left;
    width: 20%;
    margin-bottom: 10px;

    @include bp-lt($small) {
        margin-bottom: 0;
        width: auto;
        position: relative;
    }

    &.is-main {
        width: 80%;
        padding-right: 10px;
        margin-bottom: 0;
        position: relative;

        @include bp-lt($small) {
            padding-right: 0;
            width: auto;
        }

        .c-video__title {
            color: $white;
            position: absolute;
            left: 0;
            padding-left: rem(30);
            padding-right: rem(30);
            bottom: rem(30);
            font-size: rem(26);
            line-height: rem(26);
        }
    }

    a {
        color: $white;
        text-align: center;
        display: block;

        @include bp-lt($small) {
            padding-left: $gutter;
            padding-right: $gutter;
        }
    }
}

.c-video__title {
    text-align: left;
}

.c-video__thumb {
    position: relative;

    img {
        width: 100%;
    }

    [class*="c-fonticon"] {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        font-size: rem(50);
    }
}

.c-video__list {
    .c-video__title {
        position: static;
        font-size: rem(16);
        line-height: rem(16);
        color: $mainFont;
        margin-top: rem(5);

        @include bp-lt($small) {
            color: $white;
            position: absolute;
            left: 0 !important;
            right: 0 !important;
            bottom: rem(15) !important;
            font-size: rem(18) !important;
            text-align: center;
            padding-left: 20px !important;
            padding-right: 20px !important;
        }
    }
}

.c-section__video {
    &.o-section {
        @include bp-lt($small) {
            max-width: none;
        }
    }

    .c-row__video {
        @include bp-lt($small) {
            margin: 0;
        }

        .column {
            @include bp-lt($small) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
