// ========================================= //
// blocks
// ========================================= //
.c-list__block {
    .js-equalizer__item {
        @include bp-lt($small) {
            height: auto !important;
        }
    }
}

[class*="c-block--"] {
    color: $white;
    padding: rem(30) rem(20) rem(35);
    text-align: center;
    height: 100%;

    @include bp-lt($small) {
        padding: 15px 23px;
    }

    [class*="c-fonticon"] {
        font-size: rem(50);
        height: rem(50);
        margin-bottom: rem(23);

        @include bp-lt($small) {
            margin-bottom: rem(10);
            margin-right: 12px;
        }
    }

    &[class*="phone"] {
        background: $blue;

        @include bp-lt($small) {
            margin-bottom: 10px;
        }
    }

    &[class*="agenda"] {
        background: $blue--light;

        [class*="c-button"] {
            width: 100%;
            max-width: rem(245);

            @include bp-lt($small) {
                text-align: center;
                font-size: 15px;
                padding-left: 8px;
                padding-right: 8px;
                max-width: none;
            }
        }

        @include bp-lt($small) {
            text-align: left;
        }

        p {
            @include bp-lt($small) { display: none; }
        }

        [class*="c-fonticon"] {
            @include bp-lt($small) {
                display: inline-block;
                vertical-align: middle;
                margin-bottom: 0;
            }
        }

        .c-block__content {
            @include bp-lt($small) {
                display: inline-block;
                vertical-align: middle;
                width: calc(100% - 67px);
            }
        }
    }

    p {
        span {
            display: block;
            font-size: rem(16);
            font-style: italic;
            line-height: rem(22);
        }
    }
}

.c-block__content {
    height: calc(100% - (23px + 50px));

    @include bp-lt($small) {
        height: auto;
    }

    &.is-for-small {
        p {
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: rem(10);

            &.is-small {
                margin-top: rem(10);
                text-transform: none;
                font-weight: 200;
                margin-bottom: 0;
                font-size: rem(14);
                line-height: rem(18);
                font-style: italic;
                color: $blue--lighter--02;
            }
        }
    }
}

.c-agenda__holder {
    @include bp-lt($small) {
        opacity: 1 !important;
        animation: none !important;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 9;
        height: 80px;
        padding: 0 !important;
        box-shadow: 0 rem(-3) rem(3) rgba($black, 0.1);
    }

    &.is-not-fixed {
        position: static;
        padding: 0 $gutter !important;
        box-shadow: none;
    }
}

.c-subscribe {
    width: 100%;
    max-width: rem(890);
    padding-left: rem(20);
    padding-right: rem(20);
}

.c-warnings {
    > .row > .column > .c-title--section {
        position: relative;
        padding-left: rem(35);

        &::before {
            position: absolute;
            display: block;
            content: '\EA17';
            top: 0;
            left: 0;
            color: $red--01;
            font-size: rem(24);
            font-family: "iconfont";

            @include bp-lt($small) {
                font-size: rem(20);
            }

        }
    }
    > .row > .column > ul {
        // @include clearUl;
        border-top: rem(1) solid $grey--01;
        padding: rem(30) 0;

        @include bp-lt($small) {
            padding-bottom: 0;
        }

        li {
            position: relative;
            padding-left: rem(15);
            margin-bottom: rem(30);
            font-size: rem(16);
            font-weight: 300;
            text-align: left;

            @include bp-lt($small) {
                padding-left: rem(30);
                font-size: rem(14);
            }

            // &::before {
            //     position: absolute;
            //     display: block;
            //     content: '\EA17';
            //     top: rem(-5);
            //     left: 0;
            //     color: $red--01;
            //     font-size: rem(24);
            //     font-family: "iconfont";
            //
            //     @include bp-lt($small) {
            //         font-size: rem(20);
            //     }
            //
            // }
        }
    }
}


.o-procns {
    @include clearfix;

    [class*="c-procns"] {
        width: 100%;
        display: block;
        text-align: left;
        font-family: $mainFontFamily;
        padding: rem(30);
        color: $white;

        > span {
            display: block;
            padding-bottom: rem(20);
            font-size: rem(24);
            font-weight: 700;
        }

        > ul {
            @include clearUl;

            li {
                display: block;
                padding: rem(20) 0;
                border-top: rem(1) solid rgba($white, .5);
                @include clearfix;
                > span, p {
                    display: block;
                    float: left;
                }

                span {
                    width: 60px;
                    font-size: rem(40);
                    margin-left: 10px;

                    @include bp-lt($small) {
                        width: 50px;
                        margin-left: 5px !important;
                        font-size: rem(30);
                    }

                    &[class*="--bulb"] {
                        margin-left: 0;
                        font-size: rem(50);

                        @include bp-lt($small) {
                            font-size: rem(40);
                        }
                    }
                }

                p {
                    width: calc(100% - 70px);

                    @include bp-lt($small) {
                        width: calc(100% - 60px);
                        font-size: rem(14);
                    }
                }
            }
        }

        &[class*="--plus"] {
            max-width: 59%;
            float: left;
            background: $blue;

            @include bp-lt($small) {margin-bottom: rem(10);}

        }
        &[class*="--less"] {
            max-width: 39%;
            float: right;
            background: $blue--light;

        }

        @include bp-lt($small) {
            max-width: 100% !important;
            padding: rem(15);
        }
    }


}

.c-fcpi-more {
    padding-top: 0 !important;
    text-align: left;
    color: $mainFont;

    @include bp-lt($small) {
        padding-left: rem(10) !important;
        padding-right: rem(10) !important;
        padding-top: rem(20) !important;
    }

    .c-more__title {
        display: block;
        font-size: rem(24);
        font-weight: 700;
        padding: rem(20) 0;
    }

    ul {
        @include clearUl;
        display: block;
        text-align: left;
        padding: rem(30) 0;
        border-top: rem(1) solid $grey--01;


        li {
            display: inline-block;

            @include bp-lt($small) {
                width: 100%;
                display: block;
                margin-bottom: rem(10);
            }
        }
    }
}

.c-interstice {
    height: rem(304);

    img {
        width: 100%;
    }

    @include bp-lt($small) {
        height: auto;
        padding: rem(10) rem(10) rem(25) !important;
    }

    p {
        color: $white;
        font-weight: 300;
        font-size: rem(24);

        @include bp-lt(880px) {
            br {display: none;}
        }

        @include bp-lt($small) {
            font-size: rem(20);
            text-align: center;
        }
    }

    button, a {
        height: rem(50);
        padding: 0 rem(40);

        @include bp-lt($small) {
            width: calc(100% - 10px);
            height: rem(45);
            margin: 0 rem(5);
            padding: 0 rem(10);
            font-size: rem(14);
        }
    }
}

@include bp-gt(641px) {
    .row.valign-middle {
        display: table;

        > .column {
            display: table-cell;
            vertical-align: middle;
        }

        > .column, > [class*="column"] + [class*="column"]:last-child {
            float: none;
        }
    }
}

.c-blue-block__wrapper {
    margin-bottom: rem(50) !important;

    @include bp-lt($small) {margin-bottom: 0 !important;}

    > .column {
        @include bp-lt($small) {
            margin-bottom: rem(10);
        }
    }

    .c-blue-block {
        display: block;
        height: 100%;
        background: $blue;
        padding: rem(40) rem(20);

        @include bp-lt($small) {
            padding: rem(20);
        }

        .c-image {
            width: rem(100);
            display: block;
            margin: 0 auto rem(10);

            &.small {
                width: rem(130);
            }

            img {
                width: 100%;
                display: block;
            }

        }

        p {
            color: $white;
            font-size: rem(16);
            font-weight: 300;
            text-align: center;

            &.c-blue-block__baseline {
                font-size: rem(24);

                @include bp-lt($small) {
                    font-size: rem(20);
                }
            }

            i {
                font-size: rem(14);
                font-style: normal;
            }
        }

        .c-blue-block__title {
            display: block;
            font-size: rem(24);
            font-weight: 700;
            color: $white;
            text-align: center;
            margin-bottom: rem(20);

            @include bp-lt($small) {
                font-size: rem(20);
                margin-bottom: rem(10);
            }
        }
    }
}

.c-conditions {
    width: 100%;
    display: block;

    p {
        font-size: rem(14);
        font-weight: 300;
        line-height: rem(18);
    }
}
