body {
    .c-wrapper__offcanvas {
        position: relative;
        right: 0;
        transition: $bezier;
    }

    &.is-fixed {
        @include bp-lt($medium) {
            position: fixed;
            overflow: hidden;
            width: 100%;
        }

        .c-wrapper__offcanvas {
            overflow-x: hidden;

            @include bp-lt($medium) {
                position: relative;
            }
        }
    }
}

.c-nav__close {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0; left: 0;
    opacity: 0;
    pointer-events: none;
    background: rgba($mainBranding, .6);
    transition: opacity .3s ease;
    z-index: -1;

    &.is-active {
        opacity: 1;
        z-index: $zindex--fixed;
        pointer-events: auto;
    }
}
