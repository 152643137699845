.c-list__reinsurance {
    margin-left: -$gutter;
    margin-right: -$gutter;
    text-align: center;

    .c-reinsurance__item {
        width: percentage(1/4);
        display: inline-block;
        padding: 0 $gutter;
        margin-bottom: rem(25);

        @include bp-lt($tablet) {
            width: 50%;
            margin-bottom: rem(10);
        }

        @include bp-lt($small) {
            width: 100%;
            margin-bottom: 10px;
            height: 70px;
        }
    }
}

.c-reinsurance__icon {
    &.is-bigger {
        font-size: rem(100);

        @include bp-lt($small) {
            font-size: 57px;
        }

        [class*="c-fonticon"] {
            position: relative;
            top: rem(-22);

            @include bp-lt($small) { top: 0; }
        }
    }
}

.c-reinsurance__content {
    display: block;
    background: $grey--02;
    padding-top: rem(80);
    padding-bottom: rem(30);
    padding-left: rem(20);
    padding-right: rem(20);
    text-align: center;
    color: $mainFont;
    font-family: $mainFontFamily;
    height: 100%;

    @include bp-lt($medium) {
        padding-top: rem(50);
    }

    @include bp-lt($small) {
        padding: 0 rem(20);
        text-align: left;
        display: table;
        width: 100%;
    }

    &:hover, &:focus {
        color: $subBranding;

        .c-reinsurance__icon {
            top: rem(-10);

            @include bp-lt($small) {
                top: 0;
            }
        }
    }
}

.c-reinsurance__title {
    font-weight: 700;
    margin-top: rem(35);

    @include bp-lt($small) {
        margin-top: 0;
        line-height: 22px;
    }
}

.c-reinsurance__icon {
    color: $subBranding;
    font-size: rem(60);
    height: rem(60);
    position: relative;
    top: 0;
    transition: $ease;

    @include bp-lt($small) {
        font-size: 36px;
        height: 36px;
        width: 60px;
        text-align: center;
    }
}

.c-reinsurance__plus {
    color: $subBranding;
    font-size: rem(35);
    margin-top: rem(55);

    @include bp-lt($medium) {
        margin-top: rem(30);
    }

    @include bp-lt($small) {
        margin-top: 0;
        font-size: 30px;
        width: 30px;
    }
}

.c-reinsurance__description {
    @include bp-lt($small) {
        display: none;
    }
}

.c-reinsurance__equalizer {
    @include bp-lt($small) {
        height: auto !important;
        width: calc(100% - 97px);
        padding-left: $gutter;
    }
}

.c-reinsurance__icon,
.c-reinsurance__equalizer,
.c-reinsurance__plus {
    @include bp-lt($small) {
        display: table-cell;
        vertical-align: middle;
        height: 70px !important;
    }
}
