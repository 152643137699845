// ========================================= //
// plans
// ========================================= //

.o-plans {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;

    .o-plans__slider {
        width: 100%;
        max-width: $layoutWidth;
        display: block;
        clear: both;
        @include clearfix;

        .slick-list {
            @include bp-lt($tablet) {
                padding-left: 20%;
                padding-right: 20%;
            }

            @include bp-lt($small) {
                padding-left: 15%;
                padding-right: 15%;
            }

            .slick-slide {
                transition: $ease;
                &:not(.slick-current) {
                    opacity: .5;
                }
            }

        }

        .column {
            max-width: percentage(1/2);
            padding-left: rem(8);
            padding-right: rem(8);
            padding-bottom: rem(45);
            float: left;

            @include bp-lt($small) {
                padding-left: rem(5);
                padding-right: rem(5);
            }

            &:first-of-type {
                padding-left: rem(85);

                @include bp-lt($medium) {
                    padding-left: 0;
                }
            }

            &:last-of-type {
                padding-right: rem(85);

                @include bp-lt($medium) {
                    padding-right: 0;
                }
            }
        }

        .o-plan {
            position: relative;
            height: 100%;
            display: block;
            padding: rem(30) rem(20) rem(50);

            background: $grey--plans;
            text-align: center;
            font-family: $mainFontFamily;

            @include bp-lt($small) {
                padding: rem(20) rem(10) rem(30);
            }

            .o-plan__icon {
                display: block;
                margin-bottom: rem(30);
                color: $red--01;
                font-size: rem(65);
                text-align: center;

                span {
                    display: block;
                    vertical-align: middle;
                    transform: scale(1) translateZ(0);
                    transition: $ease;
                }

                @include bp-lt($small) {
                    margin-bottom: rem(10);
                }

                &:hover, &:active {
                    span {transform: scale(0.75) translateZ(0);}
                }
            }

            .o-plan__title,
            .o-plan__baseline {
                font-size: rem(18);
                font-weight: 700;
                margin-bottom: rem(5);
                padding-left: rem(10);
                padding-right: rem(10);

                @include bp-bt($small, 875px) {

                    br {
                        // display: none;
                    }
                }

                @include bp-lt($tablet) {
                    padding: 0;
                }
                @include bp-lt($small) {
                    font-size: rem(16);
                }
            }

            .o-plan__baseline {
                font-weight: 300;
                margin-bottom: rem(30);

                &.c-clotured {
                    position: relative;

                    // &::after {
                    //     width: 100%;
                    //     position: absolute;
                    //     content: 'Souscription cloturée';
                    //     display: block;
                    //     bottom: rem(-25);
                    //     left: 0;
                    //     text-align: center;
                    //     color: $subBranding;
                    //     text-transform: uppercase;
                    //     font-weight: 700;
                    //     font-size: rem(20);
                    //     // transform: rotate(-10deg);
                    //
                    //     @include bp-lt($small) {font-size: rem(15);}
                    // }

                }

                @include bp-lt($medium) {
                    padding: 0;
                }

                @include bp-lt($small) {
                    // margin-bottom: 0;
                }
            }

            .o-plan__thumbs {
                width: 100%;
                @include clearUl;
                padding-top: rem(25);
                padding-left: rem(20);
                text-align: left;
                list-style: disc;
                border-top: rem(1) solid $grey--01;

                @include bp-lt($small) {display: none;}

                li {
                    span {
                        font-size: rem(16);
                        font-weight: 300;
                    }
                }

            }

            .c-simulator__link {
                position: relative;
                display: inline-block;
                color: $red--01;
                font-size: rem(13);
                padding-right: rem(15);
                margin-top: rem(15);
                text-align: center;

                &::after {
                    position: absolute;
                    content: '\EA1C';
                    display: block;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-family: $iconFont;
                    color: $red--01;
                    font-size: rem(10);
                    vertical-align: middle;
                }

                &:hover, &:active {
                    padding-right: rem(20);
                }
            }

            [class*=c-button] {
                position: absolute;
                left: 50%;
                bottom: rem(-25);
                transform: translateX(-50%);

                @include bp-gt(1025px) {
                    width: rem(230);
                }

                @include bp-lt($medium) {
                    width: 80%;
                }

                @include bp-lt($small) {
                    width: rem(180);
                }
            }
        }
    }
}
