.o-nav {
    margin-top: rem(22);
    border-top: rem(1) solid $grey--00;

    @include bp-lt($small) {
        margin-top: 0;
        border: none;

        display: block;
        position: fixed;
        width: 100%;
        top: 60px;
        left: auto;
        right: -100%;
        height: auto;
        background: $white;
        z-index: $zindex--offcanvas;
        padding: 0;
        padding-left: rem(10);
        padding-right: rem(10);
        padding-bottom: rem(20);
        transition: $ease;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        -ms-overflow-style: none;
        &::-webkit-scrollbar { width: 0 !important }
    }

    ul {
        list-style: none;
        display: table;
        max-width: rem(670);
        width: 100%;
        margin: 0;

        @include bp-lt($tablet) {
            max-width: 100%;
        }

        @include bp-lt($small) {
            display: block;
            padding-left: rem(20);
            padding-right: rem(20);
            border-top: rem(1) solid $grey--00;
        }

        li {
            position: relative;
            display: table-cell;
            width: 1%;
            text-align: center;
            padding-top: 0;
            vertical-align: middle;

            @include bp-lt($small) {
                width: 100%;
                display: block;
                text-align: left;
                border-bottom: rem(1) solid $grey--00;

                &:nth-last-child(2),
                &:last-child {
                    border-bottom: none;
                }
            }

            > a {
                display: inline-block;
                font-size: rem(16);
                text-transform: uppercase;
                color: $black;
                font-weight: 500;
                white-space: nowrap;
                padding: rem(30) rem(20);

                @include bp-bt(640px, 768px) {
                    font-size: rem(12);
                }

                @include bp-lt($medium) {
                    padding: rem(20) rem(15);
                }

                @include bp-lt(850px) {
                    padding-left: 0;
                    padding-right: 0;
                }

                @include bp-lt($small) {
                    padding: rem(15) 0;
                    display: block;
                    white-space: normal;
                }

                @include bp-lt($mobileM) {
                    padding: rem(5) 0;
                    font-size: rem(14);
                }

                &.is-active, &:hover, &:focus {
                    // border-top: solid 1px $subBranding;
                    color: $subBranding;
                }

                &.is-active {
                    font-weight: 700;
                }


                span {
                    display: block;
                    font-size: rem(19);
                    vertical-align: middle;
                }
            }

            &:first-of-type {
                > a {
                    padding-right: rem(30);
                }
            }
            &:not(:last-of-type) {
                &::after {
                    width: rem(1);
                    height: rem(16);
                    position: absolute;
                    content: '';
                    display: inline-block;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    background: $grey--01;

                    @include bp-lt($small) {display: none;}

                    @include bp-bt(640px, 768px) {
                        height: rem(12);
                    }
                }
            }
        }
    }
}

.is-inViewport {
    left: auto;
    right: 0 !important;
    opacity: 1;
    visibility: visible;
}

$sideMenu: 21px;
.c-nav__icon {
    position: absolute;
    z-index: 310;
    right: 0;
    top: 0;
    display: none;
    margin: auto;
    width: $sideMenu;
    height: $sideMenu;
    cursor: pointer;
    padding: 0;

    &, &:hover, &:focus { background: none; }

    @include bp-lt($small) { display: block; }

    i {
        display: block;
        text-indent: rem(-9999);
        width: $sideMenu;
        height: 3px;
        position: relative;
        top: 12px;
        transform: rotate(0deg);
        transition: $ease;
        pointer-events: none;


        &:before,
        &:after {
            display: block;
            position: absolute;
            content: "";
            width: $sideMenu;
            height: 3px;
            transition: $ease;
        }

        &:before {
            top: -8px;
            transform: rotate(0deg);
        }

        &:after {
            bottom: -8px;
            transform: rotate(0deg);
        }
    }

    i, i:before, i:after {
        background: $mainBranding;
    }

    &.is-active {
        i {
            background-color: transparent;
            transform: rotate(180deg);

        &:before {
                top: 0;
                transform: rotate(45deg);
            }

            &:after {
                bottom: 0;
                transform: rotate(-45deg);
            }
        }
    }
}
