.c-list {
    margin: 0;
    padding: 0;
    list-style: none;

    &.has-marginBottom {
        margin-bottom: rem(50);
    }

    > li {
        position: relative;
        padding-left: rem(53);
        margin-bottom: rem(30);
        font-size: rem(20);

        @include bp-lt($small) {
            font-size: rem(16);
            padding-left: rem(40);
            margin-bottom: rem(20);
        }

        &:before {
            content: '\EA0C';
            font-family: $iconFont;
            font-size: rem(34);
            color: $subBranding;
            position: absolute;
            top: rem(-10);
            left: 0;

            @include bp-lt($small) {
                font-size: rem(29);
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        li {
            list-style: disc;

            ul {
                margin-left: 0;
            }

            li {
                list-style: none;
                position: relative;
                padding-left: rem(34);

                &:before {
                    content: '\EA12';
                    font-family: $iconFont;
                    font-size: rem(24);
                    position: absolute;
                    top: rem(-6);
                    left: 0;
                }
            }
        }
    }

    li {
        a {
            color: $mainBranding !important;
            display: inline;
            border-bottom: solid 1px $mainBranding;
        }
    }
}

.c-list--check {
    @include clearUl;

    li {
        position: relative;
        padding-left: rem(40);
        margin-top: rem(15);

        @include bp-lt($small) {padding-left: rem(30);}

        &::before {
            position: absolute;
            display: block;
            vertical-align: top;
            content: "\EA0C";
            left: 0;
            top: rem(-5);
            color: $red--01;
            font-size: rem(25);
            font-family: $iconFont;

            @include bp-lt($small) {font-size: rem(23);}

        }
    }
}
