.c-details {
    padding-bottom: 0 !important;

    .c-detail {
        width: 100%;
        padding: rem(30) 0;
        max-width: 852px;
        margin: 0 auto;
        @include clearfix;

        &:not(:last-of-type) {
            border-bottom: rem(2) solid $grey--01;
        }


        .c-detail__aside,
        .c-detail__content {
            width: 100%;
            float: left;

            @include bp-lt($small) {
                max-width: percentage(12/12) !important;
            }
            .c-detail__title {
                font-size: rem(20);
                font-weight: 700;

                @include bp-lt($small) {
                    margin: 0 rem(5) rem(20);
                    font-size: rem(20);
                }
            }
        }

        .c-detail__aside{
            max-width: percentage(3/12);

            .c-detail__picture {
                width: 100px;

                img {width: 100%; display: block;;}
            }
        }

        .c-detail__content {
            padding-left: rem(20);
            max-width: percentage(9/12);

            @include bp-lt($small) {padding-left: rem(5);}

            .c-detail__title {
                margin: rem(10) 0 rem(30);

                @include bp-lt($small) {
                    margin: rem(20) 0;
                }
            }

            p {
                font-size: rem(16);

                @include bp-lt($small) {
                    font-size: rem(14);
                }
            }

            ul {
                @include clearUl;
                list-style-type: none;

                li {
                    position: relative;
                    padding-left: rem(16);

                    &::before {
                        width: rem(4);
                        height: rem(4);
                        position: absolute;
                        content: '';
                        display: block;
                        top: rem(12);
                        left: 0;
                        border-radius: 50%;

                        background: $black;
                    }
                }
            }

        }


    }

    .c-warning {
        padding: 0 rem(30);
        height: rem(92);
        background: $grey--02;
        margin-bottom: rem(40);

        @include bp-lt($small) {
            margin-bottom: rem(20);
        }

        font-size: rem(15);

        span::before {
            position: relative;
            top: rem(-1);
            vertical-align: middle;
            color: $red--01;
            font-size: rem(25);
            margin-right: rem(15);
        }
    }
}

.c-tarifs .c-detail {
    .c-detail__content {
        p {
            font-size: rem(18);
        }

        ul li p {font-size: rem(16);}
    }
}
