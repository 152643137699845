// ========================================= //
// titles
// ========================================= //


[class*="c-title"] {
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    color: $black;
    font-family: $mainFontFamily;
    text-align: center;

    &[class*="--section"] {
        font-size: rem(26);
        margin-bottom: rem(20);

        @include bp-lt($small) {
            font-size: rem(24);
        }
    }

    &[class*="--white"] {
        color: $white;
    }

    &.on-left {
        text-align: left;
    }
}
