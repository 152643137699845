// ========================================= //
// form
// ========================================= //
.c-form__call {
    width: rem(245);
    margin: 0 auto;

    input {
        appearance: none;
        box-shadow: none;
        border: none;
        display: inline-block;
        vertical-align: top;
        height: rem(49);
        width: calc(100% - 74px);
        font-size: rem(12);
        font-style: italic;
        @include placeholder-color($placeholder-color);
        margin-bottom: 0;

        &[type="text"] {
            padding-left: rem(10);
            padding-right: rem(10);
        }

        &[type="submit"] {
            width: rem(74);
            font-size: rem(16);
            text-transform: uppercase;
            color: $blue;
            background-color: $blue--lighter;
            font-style: normal;
            transition: $ease;

            &:hover, &:focus {
                color: $white;
                background-color: $blue--light;
            }
        }
    }

}
