.o-inlineBlock__holder {
    letter-spacing: -0.31em;
    text-rendering: optimizespeed;
    font-family: FreeSans, Arimo, "Droid Sans", Helvetica, Arial, sans-serif;
}

.o-inlineBlock__item {
    letter-spacing: normal;
    word-spacing: normal;
    text-rendering: auto;
}
