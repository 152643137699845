// ========================================= //
// header
// ========================================= //

main {
    // @include bp-lt($small) {
    //     margin-top: rem(60);
    // }
}

.o-header {
    padding-top: rem(35);

    @include bp-lt($desktop) {
        padding-left: rem(10);
        padding-right: rem(10);
    }

    @include bp-lt($medium) {
        padding-top: rem(25);
    }

    @include bp-lt($small) {
        height: rem(60);
        padding-top: 23px;
        padding-bottom: 14px;

        // position: fixed;
        // top: 0;
        // left: 0;
        // width: 100%;
        position: relative;
        z-index: 71;
        background: $white;
    }
}

.c-header__logo {
    float: left;

    @include bp-lt($small) {
        width: 105px;
    }
}

.c-header__partner {
    float: right;

    @include bp-lt($small) {
        width: 139px;
        margin-right: 40px;
    }
}
