// ========================================= //
// links
// ========================================= //


.o-link--grey {
    height: rem(50);
    display: block;
    background: $grey--02;
    color: $mainFont;
    margin: 0 rem(5);
    padding: 0 rem(30) rem(5);
    font-size: rem(16);
    font-weight: 300;

    @include bp-lt($small) {
        width: 100%;
        height: auto;
        min-height: rem(50);
        position: relative;
        margin: 0 auto;
        padding: 0 rem(10) rem(5);
        padding-left: rem(40);
    }

    @include bp-lt($small) {
        span:not([class*="__icon"]) {
            padding-top: rem(12);
            display: block;
            margin-left: rem(5);
        }
    }

    [class*="__icon"] {
        color: $red--01;
        font-size: rem(25);
        margin: 0 rem(10);
        transform: scale(1);

        @include bp-lt($small) {
            position: absolute;
            top: rem(4);
            left: 0;
            font-size: rem(23);
        }

        &::before {
            transition: $ease;
            vertical-align: middle;
        }
    }

    &:hover, &:active {
        color: $red--01;
        [class*="__icon"]::before {transform: scale(0.75);}
    }
}
