.c-insert__holder {
    margin-bottom: rem(40);

    &:nth-child(1) {
        .c-insert__icon {
            font-size: rem(30);
        }
    }

    &:nth-child(2) {
        .c-insert__icon {
            font-size: rem(60);
            width: rem(80);
        }
    }
}

.c-insert {
    border: 2px solid $grey--06;
    text-align: center;
    padding: rem(40) rem(10);
    position: relative;

    @include bp-lt($small) {
        height: auto !important;
        padding: rem(30) rem(10);
    }

    br {
        @include bp-lt($small) { display: none; }
    }
}

.c-insert__icon {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transform: translateY(-50%);
    margin: 0 auto;
    background-color: $white;
    color: $grey--06;
    font-size: rem(45);
    width: rem(60);
}
