.c-icons {
    padding-top: rem(30);
    padding-bottom: rem(30);
    background: $grey--04;
    text-align: center;
    margin-bottom: rem(40);

    @include bp-lt($small) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.c-icons__item {
    width: percentage(1/5);
    display: inline-block;
    vertical-align: top;
    padding-left: rem(20);
    padding-right: rem(20);
    position: relative;
    text-align: center;

    @include bp-lt($desktop) {
        padding-left: rem(15);
        padding-right: rem(15);
    }

    @include bp-lt($medium) {
        padding-left: rem(10);
        padding-right: rem(10);
    }

    @include bp-lt($tablet) {
        width: percentage(1/3);
    }

    @include bp-lt($small) {
        width: 100%;
        text-align: left;
        position: relative;
        height: auto !important;
        padding-left: 100px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 40px;
        border-bottom: rem(1) solid $grey--05;
    }

    br {
        @include bp-lt($small) {
            display: none;
        }
    }

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: rem(1);
        height: calc(100% - 40px);
        background: $grey--05;

        @include bp-lt($small) {
            display: none;
        }
    }

    &:last-child {
        @include bp-lt($small) {
            border: none;
        }

        &:after {
            display: none;
        }
    }

    &:nth-child(3) {
        &:after {
            @include bp-lt($tablet) {
                display: none;
            }
        }
    }

    [class*="c-fonticon"] {
        color: $subBranding;
        font-size: rem(65);

        @include bp-lt($small) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;
            font-size: rem(55);
        }
    }
}

.c-icons__text {
    font-size: rem(16);
    line-height: rem(18);
    color: $blue--dark;
    font-family: $mainFontFamily;
    margin-top: rem(15);

    @include bp-lt($small) {
        margin-top: 0;
        font-size: rem(14);
        line-height: rem(16);
    }

    strong {
        font-size: rem(20);
        line-height: rem(24);

        @include bp-lt($small) {
            font-size: rem(16);
            line-height: rem(18);
        }
    }

    small {
        font-size: rem(13);
        line-height: rem(14);

        @include bp-lt($small) {
            display: block;
            font-size: rem(11);
            line-height: rem(12);
        }
    }
}
