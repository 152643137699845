.o-tabs {
    width: 100%;
    max-width: $layoutWidth;
    margin: 0 auto;
    padding-top: rem(45);
    display: block;

    @include bp-lt($small) {
        padding-top: rem(20);
    }

    .o-tabs__nav {
        width: 100%;
        @include clearUl;
        display: block;
        text-align: center;
        @include clearfix;
        clear: both;
        float: none;

        li {
            a {
                width: 100%;
                height: rem(80);
                max-width: percentage(1/3);
                display: block;
                float: left;
                border-bottom: rem(1) solid $grey--01;
                font-weight: 300;
                padding: rem(20);
                font-size: rem(24);
                color: $mainFont;
                font-family: $mainFontFamily;
                transition: $ease;

                &:hover, &:active, &.is-active {
                    font-weight: 700;
                }

                &.is-active {
                    font-weight: 700;
                    border-bottom-width: rem(3);
                    border-bottom-color: $red--01;
                }

                @include bp-lt($small) {
                    height: auto;
                    padding: rem(10) 0;
                    font-size: rem(20);
                }


            }
        }
    }

}

.o-tabs__content {
    > [class*="c-tab"] {
        max-width: 890px;
        margin: 0 auto;
        padding: 0 rem(20);
        display: block;

        @include bp-lt($small) {
            padding-left: rem(10);
            padding-right: rem(10);
        }


        > [class*="o-section"] {
            padding-top: rem(80);
            padding-bottom: 0;

            @include bp-lt($small) {
                padding-top: rem(40);
            }

            @include bp-lt($small) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}


.o-tabs__subNav {

    .c-link-tab {
        display: block;
    }


    ul {
        @include clearUl;
        display: block;
        @include clearfix;
    }

    li {
        &:first-child {
            float: left;

            @include bp-lt($small) {margin-bottom: rem(10);}
        }
        &:last-child {
            float: right;

            a {
                @include bp-lt($small) {
                    padding-left: rem(5);
                    padding-right: rem(40);

                    [class*="__icon"] {
                        left: auto;
                        right: 0;
                    }
                }
            }
        }

        em {font-style: normal;}

        @include bp-lt($small) {
            // float: none !important;
            // clear: both;
        }

    }
}
