.c-affix__holder {
    position: absolute;
    right: 0;
    top: 615px;

    @include bp-lt($medium) {
        top: 480px;
    }

    @include bp-lt($small) {
        display: none;
    }

    &.is-fixed {
        position: fixed;
        top: 20px;
    }
}

.c-affix {
    overflow: hidden;
    width: 95px;

    @include bp-lt($desktop) {
        width: 50px;
    }

    &.is-open {
        width: 410px;

        @include bp-lt($medium) {
            width: 365px;
        }

        .c-affix__content {
            .c-affix__phone {
                transform: translateX(95px);
                height: 275px;
                margin-bottom: 2px;

                @include bp-lt($desktop) {
                    transform: translateX(50px);
                }
            }
        }
    }

    [class*="c-button"] {
        width: 95px;
        height: 95px;
        display: block;
        text-align: center;
        font-size: rem(52);
        float: right;
        color: $white;
        margin-bottom: 2px;
        padding: 0;

        @include bp-lt($desktop) {
            width: 50px;
            height: 50px;
            font-size: 25px;
        }

        [class*="c-fonticon"] {
            position: relative;
            top: 3px;
        }

        &[class*="__phone"] {
            background-color: $blue;

            &:hover, &:focus {
                background-color: $subBranding;
            }
        }

        &[class*="__calendar"] {
            background-color: $blue--light;

            &:hover, &:focus {
                background-color: $subBranding;
            }
        }
    }
}

.c-affix__content {
    position: relative;

    .c-affix__phone {
        float: right;
        transform: translateX(100%);
        height: 0;
        transition: $ease;
    }
}
