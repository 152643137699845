// ========================================= //
// buttons
// ========================================= //
[class*="c-button"] {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: rem(16);
    font-weight: 700;
    font-family: $mainFontFamily;
    padding-left: rem(15);
    padding-right: rem(15);
    height: rem(50);

    @include bp-lt($small) {
        height: rem(45);
        text-align: center;
    }

    span {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        padding-left: rem(15);
        padding-right: rem(15);

        @include bp-lt($small) {
            padding-left: rem(10);
            padding-right: rem(10);
        }
    }

    &[class*="--blueLighter"] {
        background-color: $blue--lighter;
        color: $blue;

        &:hover, &:focus {
            background-color: $white;
        }
    }
    &[class*="--main"] {
        background-color: $red--01;
        color: $white;

        &:hover, &:focus {
            background-color: $white;
            color: $red--01;
            box-shadow: $shadow--01;
        }
    }
    &[class*="--icon"] {
        padding: 0;

        span {
            &:first-of-type {
                padding-right: rem(5);

                @include bp-lt($small) {
                    padding-right: rem(5);
                }
            }
            &:last-of-type {
                padding-left: rem(5);

                @include bp-lt($small) {
                    padding-left: rem(5);
                }
            }


            &[class*="__icon"] {
                font-size: rem(25);

                &::before {
                    display: block;
                    vertical-align: middle;
                }
            }
        }
    }

    &[class*="--shadow"] {box-shadow: $shadow--01;}
}

.c-wrapper--button {
    width: 100%;
    display: block;
    text-align: center;
}
