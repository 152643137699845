// ========================================= //
// sticky
// ========================================= //

.o-sticky {
    height: auto;
    position: fixed;
    z-index: 50;
    display: block;
    bottom: rem(20);
    right: rem(20);
    box-shadow: $shadow--01;
    border-radius: rem(10);
    color: $white;

    .c-sticky__wrapper {
        max-width: rem(260);
        width: 100%;
        position: relative;
        z-index: 2;
        display: block;
        padding: rem(30) rem(20) rem(18);
        background: $blue;
        border-radius: rem(10);
    }


    .c-title--white {
        margin-bottom: rem(10);
        text-transform: none;
    }

    p {
        text-align: center;
        font-size: rem(16);
        font-weight: 300;
        margin-bottom: rem(25);

    }

    [class*="c-button"] {
        span {
            font-size: rem(14);
        }
    }

    // .o-sticky__toggle {
    //     width: 65px;
    //     position: absolute;
    //     display: none;
    //     top: 0;
    //     left: rem(-55);
    //     z-index: 1;
    //     height: auto;
    //     padding: rem(10) rem(15) rem(10) rem(10);
    //     border-radius: rem(10);
    //     border-top-right-radius: 0;
    //     background: $blue;
    //
    //     &.is-active {
    //         width: auto;
    //         left: rem(5);
    //         z-index: 3;
    //         background: none !important;
    //         box-shadow: none !important;
    //
    //         &::before {
    //             display: block;
    //             content: '\EA1B';
    //             font-size: rem(12);
    //             font-family: $iconFont;
    //             color: $white;
    //             text-transform: none;
    //         }
    //
    //         span {
    //             display: none;
    //         }
    //     }
    //
    //
    //     span {
    //         padding: 0;
    //         font-size: rem(30);
    //         color: $white;
    //         transition: $ease;
    //     }
    //
    //     &:hover, &:active {
    //         box-shadow: $shadow--01;
    //         span {
    //             color: $blue;
    //         }
    //     }
    // }

    @include bp-lt($small) {
        // right: rem(-260);
        // bottom: rem(10);
        // transition: $ease;
        //
        // .o-sticky__toggle {display: inline-block;}
        //
        // &.is-open {
        //     right: rem(10);
        // }

        position: static;

        opacity: 1 !important;
        box-shadow: none;
        border-radius: 0;

        .c-sticky__wrapper {
            max-width: rem(285);
            margin: 0 auto;

            [class*="c-button"] {
                width: 100%;
                height: rem(40);
            }

            [class*='o-table__cell'] {text-align: center;}

        }

    }
}
