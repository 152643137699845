// ========================================= //
// footer
// ========================================= //
// #footer{
//     @include clearfix();
// 	margin: auto;
// 	max-width: $layoutWidth;
// 	text-align:left;
// 	font-family: Arial, Helvetica, sans-serif;
//     font-weight: normal;
// 	background: $grey--dark;
// 	color: $white;
//
//     h2 {
//         color: $white;
//         font-family: Arial, Helvetica, sans-serif;
//         font-weight: bold;
//     }
// }
//
// #footer .bottom{
// 	background: $black url(#{$img}c-footer__bottom.png) no-repeat rem(10) rem(3);
// 	height: rem(22);
// 	overflow: hidden;
// 	color: $white;
// 	font-size: rem(11);
// 	line-height: rem(15);
// 	text-align: right;
// 	border-top: rem(1) solid $white;
//
//     @include bp-lt($medium) {
//         background-image: none;
//         text-align: center;
//         height: auto;
//     }
//
//     ul {
//         margin: 0;
//     }
// }
//
// #footer .bottom li{
// 	display: inline-block;
// 	vertical-align: top;
// 	padding: 0 rem(10);
// 	background: url(#{$img}c-footer__separator.png) no-repeat right rem(2);
// 	margin-top: rem(3);
//     font-size: rem(11);
//     line-height: rem(15);
// }
//
// #footer .bottom li.copyright{
// 	background: none;
// }
//
// #footer .bottom a:link,
// #footer .bottom a:visited{
// 	color: $white;
// 	text-decoration:none;
// }
//
//
// #footer .top{
// 	padding: rem(16) rem(10) rem(20);
// }
//
// #footer .top h2{
// 	text-transform: uppercase;
// 	font-size: rem(13);
// 	margin-bottom: rem(19);
// }
//
// #footer .top a:link,
// #footer .top a:visited{
// 	color: $white;
// 	text-decoration:none;
// 	font-size: rem(13);
// 	line-height: rem(14);
// }
//
// #footer .top .hasIcon img{
// 	width: rem(18);
// 	float:left;
// 	margin-right: rem(8);
// 	margin-top: rem(-2);
// }
// #footer .top ul{
// 	position:relative;
// 	font-size: 0;
// 	border-top: rem(1) solid $grey--dark--01;
//     margin: 0;
// }
// #footer .top li{
// 	border-bottom: rem(1) solid $grey--dark--01;
// 	padding: rem(8) 0;
// 	display: inline-block;
// 	width: 50%;
// 	vertical-align: top;
// 	box-sizing: inherit;
// 	font-size: 0;
// }
//
// #footer .suivre{
// 	float: left;
// 	width: 50%;
//
//     @include bp-lt($small) {
//         float: none;
//         width: 100%;
//     }
// }
// #footer .groupe{
// 	float: right;
// 	width: 50%;
//
//     @include bp-lt($small) {
//         float: none;
//         width: 100%;
//     }
// }


footer.o-footer {
    width: 100%;
    display: block;
    background-color: $grey--02;

    border-top: rem(1) solid $grey--01;

    @include bp-gt($small) {
        padding-bottom: rem(250);
    }

    .c-white & {
        background-color: $white;
    }

    ul {
        display: block;
        @include clearUl;
        text-align: center;
        padding: rem(20) rem(20);

        li {
            display: inline-block;

            a {
                position: relative;
                display: block;
                color: $black;
                font-size: rem(16);
                font-weight: 300;
                padding: rem(5) rem(20);

                &:hover, &:active {
                    color: $red--01;
                }
            }


            &:not(:last-of-type) {
                a::after {
                    width: rem(1);
                    height: rem(16);
                    position: absolute;
                    display: block;
                    content: '';
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    background: $grey--darker;
                }
            }
        }
    }
}
