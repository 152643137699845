// ========================================= //
// section
// ========================================= //
[class*="o-section"] {
    padding-top: rem(80);
    padding-bottom: rem(80);
    border-top: rem(1) solid $grey--01;
    max-width: $layoutWidth;
    margin: 0 auto;

    @include bp-lt($desktop) {
        max-width: calc(100% - 20px);
    }

    @include bp-lt($medium) {
        padding-top: rem(60);
        padding-bottom: rem(60);
    }

    @include bp-lt($small) {
        max-width: none;
        padding: rem(40) rem(20);
    }
    @include bp-lt($mobileM) {
        padding: rem(20) rem(20);
    }

    &[class*="--grey"] {
        max-width: none;
        display: block;
        background: $grey--02;
    }
    &[class*="--blue"] {
        max-width: none;
        display: block;
        background: $blue;
    }

    &.no-border {
        border: none;
    }

    &.no-padding {
        padding-top: 0;
        padding-bottom: 0;

        @include bp-lt($small) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &.no-paddingTop {
        padding-top: 0 !important;
    }

    &.no-big-padding {
        padding-top: rem(50);
        padding-bottom: rem(50);

        @include bp-lt($medium) {
            padding-top: rem(40);
            padding-bottom: rem(40);
        }
    }

    &.small-full {
        @include bp-lt($small) {
            width: 100%;
            max-width: none;
        }
    }

    &.small-noBorderTop {
        @include bp-lt($small) {
            border-top: none;
        }
    }
}

.c-section__content {
    padding-bottom: 0 !important;

    p {
        display: block;
        font-size: rem(20);
        font-weight: 300;

        strong {
            display: block;
            font-weight: 700;
        }
    }

    .margin {
        margin-top: rem(30);
    }
}
