// ========================================= //
// ui
// ========================================= //

::-moz-selection { color: $selectionColor;  background: $selectionBackground; }
::selection      { color: $selectionColor;  background: $selectionBackground; }

* {
    outline: none;
}

html {
    font-size: 16px;
}

body {
    font-family: $mainFontFamily;
    line-height: rem(24);
    font-size: rem(16);
    font-weight: 400;
    color: $black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include bp-lt($medium) {
        font-size: rem(14);
    }
}

.o-table {
    display: table;
    width: 100%;
    height: 100%;
}

a {
    transition: $ease;
}

[class*='o-table__cell'] {
    display: table-cell;

    &[class*='--valignMiddle'] {
        vertical-align: middle;

        &[class*='--tabletDown'] {
            vertical-align: top;

            @include bp-lt($tablet) {
                vertical-align: middle;
            }
        }
    }

    &[class*='--valignBottom'] {
        vertical-align: bottom;
    }
}

.row {
    max-width: $layoutWidth;

    &.row--full {
        max-width: 100%;
    }

    .row {
        margin: 0 1 * $gutter;
    }

    [class*='column'] {
        padding-left: $gutter;
        padding-right: $gutter;
    }

    &.padding-small {
        [class*='column'] {
            padding-left: 3*$gutter;
            padding-right: 3*$gutter;
        }
    }
}

.no-marginBottom {
    margin-bottom: 0;
}

.small-marginBottom {
    margin-bottom: rem(15);
}

#__bs_notify__ {display: none !important; opacity: 0 !important;}

iframe[name="google_conversion_frame"] {
    position: fixed;
    height: 0;
}
