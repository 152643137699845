[class*="c-landing--"] {
    max-width: $layoutWidth;
    margin-left: auto;
    margin-right: auto;
    height: rem(300);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    margin-bottom: rem(40);

    @include bp-lt($medium) {
        margin-bottom: rem(30);
        height: rem(300);
    }

    @include bp-lt($small) {
        //margin-bottom: 0;
    }

    .row {
        height: 100%;

        .column {
            height: 100%;
            position: relative;
        }
    }

    &[class*='small'] {
        height: rem(300);
        position: relative;
        overflow: hidden;

        img {
            display: block;
            position: absolute;
            z-index: $zindex--background;
            width: rem(1024);
            max-width: none;
            height: rem(307);
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        .c-landing__caption {
            @include bp-lt($small) {
                width: 100%;
                max-width: calc(100% - 40px);
            }
        }
    }

    &[class*="home"] {
        width: 100%;
        height: rem(400);
        max-width: none;
        background-image: url(#{$img}c-img__landing--home.jpg);

        @include bp-lt($medium) {
            height: rem(300);
        }

        @include bp-lt($small) {
            background-position: top 50% left -150px;
        }

        .c-landing__caption {
            bottom: rem(52);
            right: rem(-58);

            @include bp-lt($desktop) {
                right: rem(20);
            }

            @include bp-lt($small) {
                right: 0;
            }
        }
    }

    &[class*="35years"] {
        background-image: url(#{$img}c-img__landing--35year.jpg);

        @include bp-lt($small) {
            background-image: url(#{$img}c-img__landing--35year--small.jpg);
            background-position: top 0 left 50%;
        }
    }

    &[class*="project"] {
        &[class*='small'] img {
            @include bp-lt($small) {
                left: 60%;
            }
        }
    }

    &[class*="partners"] {
        //background-image: url(#{$img}c-img__landing--partners.jpg);
    }
}

.c-landing {
    img {
        width: 100%;
    }
}

.c-landing__caption {
    position: absolute;
    z-index: $zindex--content;
    right: rem(35);
    bottom: rem(35);
    font-size: rem(28);
    line-height: rem(36);
    font-weight: 700;
    text-transform: uppercase;
    padding: rem(58) rem(60);
    background: $white;
    background: rgba($white, 0.8);
    box-shadow: 0 0 rem(8) rgba($black, 0.1);
    width: 100%;
    max-width: rem(512);

    @include bp-lt($medium) {
        padding: rem(40) rem(40);
    }

    @include bp-lt($small) {
        padding: 40px 20px;
        font-size: rem(24);
        max-width: 280px;
        margin: 0 auto;
        left: 0;
        right: 0;
        text-align: center;
        line-height: rem(26);
        bottom: rem(20);
    }

    &.is-small {
        font-size: rem(26);
        padding: rem(35) rem(40);
        max-width: rem(416);
    }
}
