// ========================================= //
// content
// ========================================= //
p {
    //font-weight: 200;
    font-size: rem(18);
    line-height: rem(24);

    @include bp-lt($medium) {
        font-size: rem(16);
        line-height: rem(20);
    }

    &.is-big {
        font-size: rem(20);
    }

    &.is-bigger {
        font-size: rem(24);
        line-height: rem(30);
        margin-bottom: rem(40);

        @include bp-lt($medium) {
            margin-bottom: rem(30);
        }

        @include bp-lt($small) {
            font-size: rem(18);
        }
    }

    &.small-bold {
        @include bp-lt($small) {
            font-weight: 700;
        }
    }

    a {
        color: $mainBranding !important;
        display: inline;
        border-bottom: solid 1px $mainBranding;
    }
}

.o-fz__small--18 {
    @include bp-lt($small) {
        font-size: 18px !important;
    }
}

.o-fz__small--16 {
    @include bp-lt($small) {
        font-size: 16px !important;
    }
}

.o-fz__small--14 {
    @include bp-lt($small) {
        font-size: 14px !important;
    }
}

.o-fz__small--20 {
    @include bp-lt($small) {
        font-size: 20px !important;
    }
}

li {
    font-size: rem(18);

    @include bp-lt($medium) {
        font-size: rem(16);
    }
}

h1 {
    text-transform: uppercase;
    font-family: $mainFontFamily;
    font-weight: 700;
    font-size: rem(30);
    line-height: rem(34);
    margin-top: 0;
    margin-bottom: rem(40);

    @include bp-lt($medium) {
        font-size: rem(24);
        line-height: rem(28);
    }

    .c-subTitle {
        display: block;
        text-transform: none;
    }
}

h2 {
    margin-top: 0;
    margin-bottom: rem(40);
    text-transform: uppercase;
    font-weight: 700;
    font-size: rem(26);
    font-family: $mainFontFamily;

    @include bp-lt($medium) {
        margin-bottom: rem(30);
        font-size: rem(24);
    }

    @include bp-lt($mobileL) {
        margin-bottom: rem(20);
        font-size: rem(22);
        line-height: rem(28);
    }

    &.is-alt {
        line-height: rem(36);
        margin-bottom: rem(15);
        text-transform: none;

        @include bp-lt($mobileL) {
            line-height: rem(25);
        }
    }
}

h2.is-alt + p {}

h3 {
    margin: 0 0 rem(10);
    text-transform: uppercase;
    font-weight: 700;
    font-size: rem(20);
    font-family: $mainFontFamily;

    @include bp-lt($medium) {
        font-size: rem(18);
    }
}

.c-title {
    @include bp-lt($small) {
        padding-left: rem(30);
        padding-right: rem(30);
    }
}

.c-col__wrapper {
    padding-left: rem(55);

    @include bp-lt($small) {
        padding-left: 0;
    }
}

.c-conditions {
    margin-top: rem(20);
    font-size: rem(14);
    line-height: rem(14);

    @include bp-lt($small) {
        font-size: rem(12);
        line-height: rem(12);
    }

    .c-section__video & {
        margin-top: rem(10);

        @include bp-lt($small) {
            padding: 0 rem(5);
        }
    }
}

.text-uppercase {
    text-transform: uppercase;
}

ul.c-list--checked {
    list-style: none;
    margin-left: 0;
    margin-bottom: rem(45);
    margin-top: rem(45);

    @include bp-lt($small) {
        margin-bottom: rem(25);
        margin-top: rem(25);
    }

    li {
        position: relative;
        padding-left: rem(50);
        font-size: rem(20);

        &.margin-bottom {
            margin-bottom: rem(20);
        }

        &:before {
            position: absolute;
            top: rem(-2);
            left: 0;
            display: block;
            width: rem(34);
            height: rem(34);
            content: '';
            background: url(#{$img}c-icon__check.svg);
        }

        a {
            color: $mainBranding;
            display: inline;
            border-bottom: solid 1px $mainBranding;
        }
    }
}

.c-solidarity {
    text-align: center;
    padding: rem(35) rem(20);
    background-color: $grey--02;

    p:last-child {
        margin-bottom: 0;
    }
}

.c-finansol {
    margin-top: rem(40);
    margin-left: auto;
    margin-right: auto;
    max-width: 780px;
    font-size: rem(14);
    line-height: rem(16);

    @include bp-lt($small) {
        max-width: none;
        margin-top: rem(30);
    }

    .c-finansol__img {
        width: 63px;
        display: inline-block;
        vertical-align: top;

        @include bp-lt($small) {
            width: 100%;
            display: block;
            text-align: center;
            position: relative;
            padding-bottom: rem(13);
            margin-bottom: rem(13);
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 70%;
            height: 2px;
            background-color: $grey--07;
            margin: 0 auto;
        }
    }

    .c-finansol__content {
        width: calc(100% - (63px + 15px));
        display: inline-block;
        vertical-align: top;
        border-left: 2px solid $grey--07;
        padding-left: rem(15);
        margin-left: rem(15);

        @include bp-lt($small) {
            border-left: none;
            padding: 0 rem(5);
            text-align: center;
            width: 100%;
            margin: 0;
        }
    }
}
