// ========================================= //
// colors
// ========================================= //

$white                  : #ffffff !default;
$black                  : #000000 !default;
$error                  : #da4f4f !default;
$grey                   : #9d9da6 !default;
$red                    : #ba2327 !default;
$orange                 : #ffa279 !default;

$selectionColor         : $white !default;
$selectionBackground    : $grey !default;

$mainBranding           : $black !default;
$subBranding            : #e5224c !default;

$mainFont               : $mainBranding !default;

$grey--dark             : #333333 !default;
$grey--dark--01         : #4d4d4d !default;

$grey--00               : #eaeaea !default;
$grey--01               : #e5e5e5 !default;
$grey--02               : #f9f9f9 !default;
$grey--03               : #999999 !default;
$grey--04               : #eeeeee !default;
$grey--05               : #d6d6d6 !default;
$grey--06               : #b8b8b8 !default;
$grey--07               : #cdcdcd !default;

$grey--simulator        : #eeeeee !default;

$blue                   : #4a7896 !default;
$blue--light            : #7d98af !default;
$blue--lighter          : #d1dde4 !default;
$blue--lighter--01      : #e5eaef !default;
$blue--lighter--02      : #a3baca !default;

$blue--dark             : #002842 !default;

$placeholder-color      : #808284 !default;

$red--01: #e5022f !default;

$grey--darker: #686868 !default;
$grey--plans: #f5f5f5 !default;

$shadow--01 : 0 rem(5) rem(15) 0 rgba(0, 40, 66, 0.2);
