.to-animate {
    opacity: 0;

    &.animated {
        opacity: 1;
    }
}

.animated {
    animation-duration: 1s;
}
