.c-col__separated {
    margin-bottom: rem(25);

    @include bp-lt($small) {
        margin-bottom: 0;
    }

    .column {
        padding-top: rem(10);
        padding-bottom: rem(30);

        @include bp-lt($small) {
            padding-top: 0;
            padding-bottom: rem(25);
        }

        &:last-child {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                width: rem(1);
                height: 100%;
                top: 0;
                left: $gutter;
                background: $grey--01;

                @include bp-lt($small) {
                    display: none;
                }
            }
        }
    }

    ul {
        margin-bottom: 0;
    }
}
