// ========================================= //
// typos
// ========================================= //

.c-simulator__small {
    text-align: left;
}

.c-small {
    margin-top: 20px;
    font-size: 22px !important;
}
