// ========================================= //
// popin
// ========================================= //

.bs-player {
    width: 512px !important;
    height: 288px !important;

    @include bp-lt($tabletS) { width: 400px !important; }
    @include bp-lt($xsmall) { width: 310px !important; }
}

.o-simulator {
    width: rem(900);
    height: rem(720);
    position: relative;
    margin: auto;
    background: $grey--simulator;

    overflow-y: auto;
    _overflow: auto;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar
    {
        -webkit-appearance: none;
        width: rem(14);
        height: rem(14);
    }

    &::-webkit-scrollbar-thumb
    {
        border-radius: rem(8);
        border: rem(3) solid $white;
        background-color: $mainBranding;
    }

    @include bp-lt($medium) {
        width: 90%;
        max-height: 90%;
    }

    @include bp-lt($small) {
        width: 95%;
        margin-top: rem(20);
        padding-top: rem(10);
    }
    iframe {
        width: 100%;
        height: 100%;
        border: none;

    }


    .mfp-close {
        position: absolute;
        top: rem(10);
        right: rem(10);
        transition: $ease;

        &:hover, &:active {
            color: $red--01;
        }

        @include bp-lt($small) {
            top: rem(-10);
        }
    }
}
