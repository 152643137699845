// ========================================= //
// slider
// ========================================= //
[class*="c-slider__arrow"] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $white;
    z-index: 9;
    opacity: 0.8;

    &[class*="--prev"] {
        left: 15px;
    }

    &[class*="--next"] {
        right: 15px;
    }
}


.slick-dots {
    width: 100%;
    display: block;
    margin: 0;
    line-height: normal;
    text-align: center;

    li {
        display: inline-block;
        padding-left: rem(5);
        padding-right: rem(5);

        button {
            width: rem(30);
            height: rem(2);
            padding: 0;
            margin: 0;
            background: $grey--01;
            font-size: 0;


            &:hover, &:active {
                background: rgba($red--01, .5);
            }
        }

        &.slick-active {
            button {
                height: rem(4);
                background: $red--01;
            }
        }
    }

}
